const projekteContent = {
    german: {
        headline: "PROJEKTE",
        pocket: "Lange Projektbeschreibung A Pocket of Dyke Feelings",
        ausstellen: "Lange Projektbeschreibung Ausstellen Einstellen",
        operation: "Lange Projektbeschreibung Operation Alien Love #1",
        dark: "Lange Projektbeschreibung Dark Matters",
        documenting: "Lange Projektbeschreibung Documenting Nocturnal Flâneuseries",
        dosta: "Lange Projektbeschreibung Dosta dosta (genug, genug)",
        edit: "Lange Projektbeschreibung Edit-a-night",
        extinct: "Lange Projektbeschreibung Extinct",
        feeltank: "Lange Projektbeschreibung Feel Tank: Feminist Burnout",
        femmag: "Lange Projektbeschreibung FemMag",
        fetisch: "Lange Projektbeschreibung FETISCH. poetische theorie performance",
        hilma: "Lange Projektbeschreibung HILMA – An die Noch-Nicht-Geborenen",
        dream: "Lange Projektbeschreibung I dreamt a little dream",
        neha: "Lange Projektbeschreibung Eröffnungsrede \"Women walking and loitering at Night\"",
        night: "Lange Projektbeschreibung Night Song",
        bodybuilding: "Lange Projektbeschreibung Nocturnal Queer Bodybuilding",
        vampire: "Lange Projektbeschreibung Not another vampire movie",
        pretty: "Lange Projektbeschreibung Pretty Woman // That's not my name",
        hexenkonvent: "Lange Projektbeschreibung Ranzfurter Hexenkonvent",
        schlaflos: "Lange Projektbeschreibung Rundbogen gegen Schlaflosigkeit",
        schleim: "Lange Projektbeschreibung Schleim is (part of) the answer",
        hidden: "Lange Projektbeschreibung The Hidden Side of the Moon: Towards a Queer-Feminist Dream Interpretation",
        strange: "Lange Projektbeschreibung The Strange Half-Absence of Wandering at Night",
        toss: "Lange Projektbeschreibung toss and turn",
        waxen: "Lange Projektbeschreibung Wax-en",
        whitecube: "Lange Projektbeschreibung WHITE CUBE_dekolonial",
        opening: "Eröffnungsrede: ",


    },
    english:  {
        headline: "PROJECTS",
        pocket: "Long project description A Pocket of Dyke Feelings",
        ausstellen: "Long project description Ausstellen Einstellen",
        operation: "Long project description Operation Alien Love #1",
        dark: "Long project description Dark Matters",
        documenting: "Long project description Documenting Nocturnal Flâneuseries",
        dosta: "Long project description Dosta dosta (genug, genug)",
        edit: "Long project description Edit-a-night",
        extinct: "Long project description Extinct",
        feeltank: "Long project description Feel Tank: Feminist Burnout",
        femmag: "Long project description FemMag",
        fetisch: "Long project description FETISCH. poetische theorie performance",
        hilma: "Long project description HILMA – An die Noch-Nicht-Geborenen",
        dream: "Long project description I dreamt a little dream",
        neha: "Long project description opening lecture \"Women walking and loitering at Night\"",
        night: "Long project description night song",
        bodybuilding: "Long project description Nocturnal Queer Bodybuilding",
        vampire: "Long project description Not another vampire movie",
        pretty: "Long project description Pretty Woman // That's not my name",
        hexenkonvent: "Long project description Ranzfurter Hexenkonvent",
        schlaflos: "Long project description Rundbogen gegen Schlaflosigkeit",
        schleim: "Long project description Schleim is (part of) the answer",
        hidden: "Long project description The Hidden Side of the Moon: Towards a Queer-Feminist Dream Interpretation",
        strange: "Long project description The Strange Half-Absence of Wandering at Night",
        toss: "Long project description toss and turn",
        waxen: "Long project description Wax-en",
        whitecube: "Long project description WHITE CUBE_dekolonial",
        opening: "Opening Lecture: ",
        
    }
}

export {projekteContent}