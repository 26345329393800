import React, { useContext } from "react"
import { Link } from "gatsby"
import Template from "../components/template"
import "../styles/projekte.css"
import { projekteContent } from "../content/projekte"
import { SettingsContext } from "../contexts/settings"

const KuenstlerinnenPage = () => {
    const [settings, ] = useContext(SettingsContext)
    const content = projekteContent[settings.language]
    return (
        <Template currentPage="projects">
            <section>
                <div>
                    <h1>{content.headline}</h1>
                    <div className="project-container">
                        <div className="project-third">
                            <Link to="/veranstaltung/a-pocket-of-dyke-feelings" className="link-project">
                                <img className="project-pic" src="/images/projekte/A_Pocket_of_Dyke_Feelings.jpg" alt={content.pocket}/>
                                <p className="project-name">A Pocket of Dyke Feelings</p>
                            </Link>
                        </div>
                        <div className="project-third">
                            <Link to="/veranstaltung/ausstellen-einstellen" className="link-project">
                                <img className="project-pic" src="/images/projekte/Projekte_ohne_Bild_Platzhalter-Kasten.png" alt={content.ausstellen}/>
                                <p className="project-name">Ausstellen Einstellen</p>
                            </Link>
                        </div>
                        <div className="project-third">
                            <Link to="/veranstaltung/dark-matters" className="link-project">
                                <img className="project-pic" src="/images/projekte/Dark_Matters.jpg" alt={content.dark}/>
                                <p className="project-name">d ark m atters x a chaotic happening</p>
                            </Link>
                        </div>
                    </div>
                    <div className="project-container">
                        <div className="project-third">
                            <Link to="/veranstaltung/documenting-nocturnal-flaneuseries" className="link-project">
                                <img className="project-pic" src="/images/projekte/Documenting_Nocturnal_Flaneuseries.png" alt={content.documenting}/>
                                <p className="project-name">Documenting Nocturnal Flâneuseries</p>
                            </Link>
                        </div>
                        <div className="project-third">
                            <Link to="/veranstaltung/Dosta-Dosta-genug-genug" className="link-project">
                                <img className="project-pic" src="/images/projekte/Dosta_dosta_genug_genug.jpg" alt={content.dosta}/>
                                <p className="project-name">Dosta dosta <br/>(genug, genug)</p>
                            </Link>
                        </div>
                        <div className="project-third">
                            <Link to="/veranstaltung/edit-a-night" className="link-project">
                                <img className="project-pic" src="/images/projekte/Edit-a-night.jpg" alt={content.edit}/>
                                <p className="project-name">Edit-a-night</p>
                            </Link>
                        </div>
                    </div>
                    <div className="project-container">
                        <div className="project-third">
                            <Link to="/veranstaltung/women-walking-and-loitering-at-night" className="link-project">
                                <img className="project-pic" src="/images/projekte/Opening_Lecture_Neha_Singh.jpg" alt={content.neha}/>
                                <p className="project-name">{content.opening} Women Walking and Loitering at Night </p>
                            </Link>
                        </div>
                        <div className="project-third">
                            <Link to="/veranstaltung/extinct" className="link-project">
                                <img className="project-pic" src="/images/projekte/Extinct.jpg" alt={content.extinct}/>
                                <p className="project-name">Extinct</p>
                            </Link>
                        </div>
                        <div className="project-third">
                            <Link to="/veranstaltung/feel-tank-feminist-burnout" className="link-project">
                                <img className="project-pic" src="/images/projekte/Feel_Tank.jpg" alt={content.feeltank}/>
                                <p className="project-name">Feel Tank: Feminist Burnout</p>
                            </Link>
                        </div>
                    </div>
                    <div className="project-container">
                        <div className="project-third">
                            <Link to="/veranstaltung/femmag" className="link-project">
                                <img className="project-pic" src="/images/projekte/FemMag.jpg" alt={content.femmag}/>
                                <p className="project-name">FemMag</p>
                            </Link>
                        </div>
                        <div className="project-third">
                            <Link to="/veranstaltung/fetisch-poetische-theorie-performance" className="link-project">
                                <img className="project-pic" src="/images/projekte/Fetisch.png" alt={content.fetisch}/>
                                <p className="project-name"><del>FETISCH</del>. poetische theorie performance</p>
                            </Link>
                        </div>
                        <div className="project-third">
                            <Link to="/veranstaltung/hilma-an-die-noch-nicht-geborenen" className="link-project">
                                <img className="project-pic" src="/images/projekte/Hilma-An die Noch-Nicht-Geborenen.jpg" alt={content.hilma}/>
                                <p className="project-name">HILMA – An die Noch-Nicht-Geborenen</p>
                            </Link>
                        </div>
                    </div>
                    <div className="project-container"> 
                        <div className="project-third">
                            <Link to="/veranstaltung/i-dreamt-a-little-dream" className="link-project">
                                <img className="project-pic" src="/images/projekte/I_dreamt_a_little_dream.JPG" alt={content.dream}/>
                                <p className="project-name">I dreamt a little dream</p>
                            </Link>
                        </div>
                        <div className="project-third">
                            <Link to="/veranstaltung/night-song-walk" className="link-project">
                                <img className="project-pic" src="/images/projekte/Night_Song.jpg" alt={content.night}/>
                                <p className="project-name">night song</p>
                            </Link>
                        </div>
                        <div className="project-third">
                            <Link to="/veranstaltung/nocturnal-queer-bodybuilding" className="link-project">
                                <img className="project-pic" src="/images/projekte/Queer_Nocturnal_Bodybuilding.png" alt={content.bodybuilding}/>
                                <p className="project-name">Nocturnal Queer Bodybuilding</p>
                            </Link>
                        </div>
                    </div>
                    <div className="project-container">
                        <div className="project-third">
                            <Link to="/veranstaltung/not-another-vampire-movie1" className="link-project">
                                <img className="project-pic" src="/images/projekte/Not_another_vampire_movie.png" alt={content.vampire}/>
                                <p className="project-name">Not another vampire movie</p>
                            </Link>
                        </div>
                        <div className="project-third">
                            <Link to="/veranstaltung/operation-alien-love-1" className="link-project">
                                <img className="project-pic" src="/images/projekte/Operation_Alien_Love.jpg" alt={content.operation}/>
                                <p className="project-name">Operation Alien Love #1</p>
                            </Link>
                        </div>
                        <div className="project-third">
                            <Link to="/veranstaltung/pretty-women" className="link-project">
                                <img className="project-pic" src="/images/projekte/Pretty_Woman.jpg" alt={content.pretty}/>
                                <p className="project-name">Pretty Woman // That's not my name</p>
                            </Link>
                        </div>
                    </div>
                    <div className="project-container">
                        <div className="project-third">
                            <Link to="/veranstaltung/ranzfurter-hexenkonvent" className="link-project">
                                <img className="project-pic" src="/images/projekte/Ranzfurter_Schwestern.png" alt={content.hexenkonvent}/>
                                <p className="project-name">Ranzfurter Hexenkonvent</p>
                            </Link>
                        </div>
                        <div className="project-third">
                            <Link to="/veranstaltung/rundbogen-gegen-schlaflosigkeit" className="link-project">
                                <img className="project-pic" src="/images/projekte/Schlaflos.jpg" alt={content.schlaflos}/>
                                <p className="project-name">Rundbogen gegen Schlaflosigkeit</p>
                            </Link>
                        </div>
                        <div className="project-third">
                            <Link to="/veranstaltung/schleim-is-part-of-the-answer" className="link-project">
                                <img className="project-pic" src="/images/projekte/schleim_is_part_of_the_answer.png" alt={content.schleim}/>
                                <p className="project-name">SCHLEIM IS (PART OF) THE ANSWER</p>
                            </Link>
                        </div>
                    </div>
                    <div className="project-container">
                        <div className="project-third">
                            <Link to="/veranstaltung/the-hidden-side-of-the-moon/" className="link-project">
                                <img className="project-pic" src="/images/projekte/The_Hidden_Side_of_the_Moon.jpeg" alt={content.hidden}/>
                                <p className="project-name">The Hidden Side of the Moon: Towards a Queer-Feminist Dream Interpretation</p>
                            </Link>
                        </div>
                        <div className="project-third">
                            <Link to="/veranstaltung/the-strange-half-absence-of-walking-at-night" className="link-project">
                                <img className="project-pic" src="/images/projekte/The_Strange_Half-Absence_of_Wandering_at_Night.jpg" alt={content.strange}/>
                                <p className="project-name">The Strange Half-Absence of Wandering at Night</p>
                                
                            </Link>
                        </div>
                        <div className="project-third">
                            <Link to="/veranstaltung/toss-and-turn" className="link-project">
                                <img className="project-pic" src="/images/projekte/toss_and_turn.jpg" alt={content.toss}/>
                                <p className="project-name">toss and turn</p>
                            </Link>
                        </div>
                    </div>
                    <div className="project-container-last project-start-left">
                        <div className="project-third">
                            <Link to="/veranstaltung/wax-en" className="link-project">
                                <img className="project-pic" src="/images/projekte/Wax_en.jpg" alt={content.waxen}/>
                                <p className="project-name">WAX-en</p>
                            </Link>
                        </div>
                        <div className="project-third">
                            <Link to="/veranstaltung/white-cube_dekolonial1" className="link-project">
                                <img className="project-pic" src="/images/projekte/WHITE CUBE_dekolonial_Foto_von_Gary_Emekwa.jpg" alt={content.whitecube}/>
                                <p className="project-name">WHITE CUBE_dekolonial</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </Template>
    )
}

export default KuenstlerinnenPage